import './OpenTelegram.css'


export default function OpenTelegram() {
    
    
    
    
    
    return (
        <div className="open-telegram">
            Please open telegram
            
        </div>
    )
}