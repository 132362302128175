import { ModalReg, PopUpMessage } from "../../../../modal/ModalReg/PopUpMessage"
import { ButtonClose } from "../../../ui/Buttons"
import Slider from 'rc-slider';
import { useState, useContext, useEffect } from 'react';
import MainContext from '../../../../context/Main'
import { useLanguage } from "../../../../data/language";

import {ApiCall} from "../../../../api/Api";
import { Exchange } from "./buttons/Exchange";

export function MarketOperation({ in_open, in_onClick, item, data, state, setState }) {
    const { lang } = useLanguage()

    const {MarketOperation} = ApiCall()

    const AppData = useContext(MainContext)

    const [maxSellItems, setMaxSellItems] = useState(data.global.threshold)
    const [maxBuyItems, setMaxBuyItems] = useState(data.global.threshold)

    const [price, setPrice] = useState(0)
    const [count, setCount] = useState(0)

    const [exchangeButton, setExchangeButton] = useState(0)
    const [alertError, setAlertError] = useState(false)

    useEffect(() => {
        // Trigger reload island's data from backend 
        AppData.triggers.setIslandCall(AppData.triggers.islandCall + 1)
        let maxSell = Math.round(AppData.island.resources[item.name])
        if (maxSell < maxSellItems) {
            setMaxSellItems(maxSell)
        }


        let user_max_count_to_buy = Math.round(AppData.island.resources.dooblons / data.global.price[item.name].buy_price)

        if (user_max_count_to_buy < data.global.threshold) {
            setMaxBuyItems(user_max_count_to_buy)
        }

    }, [state]);


    function rangeSliderSell(value) {
        setPrice(Math.round(value * data.global.price[item.name].sell_price))
        setCount(value)
    }


    function rangeSliderBuy(value) {
        setPrice(Math.round(value * data.global.price[item.name].buy_price))
        setCount(value)
    }


    useEffect(() => {
        // if to prevent from initial loading
        if (exchangeButton > 0) {

            MarketOperation(item.type, item.name, count)
                .then(response => {
                    const status = response['status']
                    // const data = response['data']

                    if (status !== 200) {
                        console.error('Smth went wrong:', response['status']);
                        setAlertError(true)

                    }
                    else {
                        AppData.triggers.setIslandCall(AppData.triggers.islandCall + 1)
                        setState(false)
                    }

                })
                .catch(error => {
                    console.error('Error:', error);
                    // Handle the error, e.g., show an error message
                });
        }
    }, [exchangeButton]);


    return (
        <>
            {state &&
                <ModalReg open={in_open}>
                    <ButtonClose onClick={in_onClick} />

                    <div className="market_exchange_price">
                        <p>
                            <b>{lang().buildings.market.exchange.count}</b>
                            {count}
                        </p>
                        <p>
                            <b>{lang().buildings.market.exchange.price}</b>
                            {price}
                            <img className='res_image' alt={lang().ui_text.resources.dooblons} src='/ui/resources/dooblons.webp'></img>
                        </p>
                    </div>

                    <Exchange onClick={() => setExchangeButton(exchangeButton + 1)} />



                    {item.type === "sell" &&

                        <>

                            <p>{lang().buildings.market.exchange.descr_start} <b>{item.name}</b> {lang().buildings.market.exchange.descr_sell}</p>

                            <Slider
                                style={{ width: 200, marginBottom: '10px' }}
                                min={0}
                                max={maxSellItems}
                                onChange={rangeSliderSell}
                                defaultValue={0}

                                trackStyle={{ backgroundColor: '#746012', height: 10 }}
                                handleStyle={{
                                    borderColor: 'blue',
                                    height: 16,
                                    width: 16,
                                    backgroundColor: 'black',
                                }}
                                railStyle={{ backgroundColor: '#ceb34a', height: 10 }}
                            />
                        </>
                    }

                    {item.type === "buy" &&

                        <>
                            <p>{lang().buildings.market.exchange.descr_start} <b>{item.name}</b> {lang().buildings.market.exchange.descr_buy}</p>



                            <Slider
                                style={{ width: 200, marginBottom: '10px' }}
                                min={0}
                                max={maxBuyItems}
                                onChange={rangeSliderBuy}
                                defaultValue={0}

                                trackStyle={{ backgroundColor: '#746012', height: 10 }}
                                handleStyle={{
                                    borderColor: 'blue',
                                    height: 16,
                                    width: 16,
                                    backgroundColor: 'black',
                                }}
                                railStyle={{ backgroundColor: '#ceb34a', height: 10 }}
                            />
                        </>

                    }


                </ModalReg>
            }
            {
                PopUpMessage({
                    in_open: alertError,
                    in_onClick: () => setAlertError(false),
                    title: lang().buildings.market.alerts.error_01.title,
                    message: lang().buildings.market.alerts.error_01.message
                })
            }
        </>


    )
}