import './MarketBuySell.css'
import { MarketOperation } from "../ModalOperation";
import { useState, useEffect } from 'react'

export function MarketBuySell({ item, type, data, trigger }) {

    const [stateOperation, setStateOperation] = useState(false)


    useEffect(() => {
        trigger.setTriggerMarketDataCall(trigger.triggerMarketDataCall + 1)

    }, [stateOperation]);

    return (
        <>
            {
                MarketOperation({
                    in_open: stateOperation,
                    in_onClick: () => setStateOperation(false),
                    item: {
                        name: item,
                        type: type,
                        data: data
                    },
                    data: data,
                    state: stateOperation,
                    setState: setStateOperation

                })
            }



            <div className={'market_' + type}>
                <button className='market_button' onClick={() => setStateOperation(true)}>
                    <img
                        src={'/ui/buttons/market_' + type + '.webp'}
                        alt={type}
                    />
                </button>
            </div>
        </>

    )
} 