import "./Market.css"
import { MarketBuySell } from "./buttons/MarketBuySell";
import { useLanguage } from '../../../../data/language';

import { useEffect, useState } from 'react'
import {ApiCall} from "../../../../api/Api";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



export default function Market() {

    const { lang } = useLanguage()

    const {GetMarketData} = ApiCall()
 
    const [marketData, setMarketData] = useState()
    const [triggerMarketDataCall, setTriggerMarketDataCall] = useState(0)

    const trigger = {
        triggerMarketDataCall: triggerMarketDataCall,
        setTriggerMarketDataCall: setTriggerMarketDataCall
    }


    useEffect(() => {

        GetMarketData()
            .then(response => {
                const status = response['status']
                const data = response['data']

                if (status !== 200) {
                    console.error('[GetMarketData]. Smth went wrong:', response['status']);
                }
                else {
                    setMarketData(data)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error, e.g., show an error message
            });
    }, [triggerMarketDataCall]);


    return (

        <div className="build_field_text">
            {lang().buildings.market.rate_description}

            <div className='swiper_parent'>
                {/* if marketData is defined: */}
                {marketData ? (
                    <>



                        <div className="swiper_content">



                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={10}
                                slidesPerView={1}
                                navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                                pagination={{ clickable: true, dynamicBullets: true }}
                                // scrollbar={{ draggable: true }}
                                // onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={() => console.log('slide change')}
                            >


                                {
                                    Object.entries(marketData.global.price).map(([commodity, details]) => (

                                        <SwiperSlide key={commodity}>
                                            <div className='market_grid'>

                                                <div className='market_product'>
                                                    <img src={"/ui/resources/" + commodity + "-256.webp"} alt={lang().ui_text.resources[commodity]} />
                                                </div>
                                                <div className='market_buy_rate'>
                                                    {Math.round(details.buy_price * 100) / 100}
                                                </div>
                                                <div className='market_sell_rate'>
                                                    {Math.round(details.sell_price * 100) / 100}
                                                </div>
                                        
                                                <MarketBuySell item={commodity} type="buy" data={marketData} trigger={trigger} />
                                                <MarketBuySell item={commodity} type="sell" data={marketData} trigger={trigger} />
                                            </div>


                                        </SwiperSlide>




                                    ))
                                }

                                <br />
                                <br />

                            </Swiper>


                        </div>
                        <div className="swiper_arrow_left">
                            <button className="arrow-left arrow">
                                <img
                                    src='/ui/buttons/left.webp'
                                    alt="Prev"
                                    width="20px"
                                    height="120px"

                                />
                            </button>


                        </div>
                        <div className="swiper_arrow_right">
                            <button className="arrow-right arrow">
                                <img
                                    src='/ui/buttons/right.webp'
                                    alt="Next"
                                    width="20px"
                                    height="120px"
                                />
                            </button>
                        </div>




                    </>

                ) : (
                    <div>Loading...</div>
                )
                }






            </div >
        </div>
    )
}