import './ErrorConnection.css'
import { useEffect } from 'react';


export default function ErrorConnection() {

    useEffect(() => {
        // Add a class to the body for specific styling
        document.body.classList.add('error-connection-active');

        // Cleanup: remove the class when the component unmounts
        return () => {
            document.body.classList.remove('error-connection-active');
        };
    }, []);
    
    useEffect(() => {
        // Simulate loading effect removal after 1 second
        const timer = setTimeout(() => {
            document.body.classList.remove('loading');
        }, 1000);

        // Cleanup timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="errorConnection">
            <h1>599</h1>
            <h2>Server is not available at the moment. Please try again later <b>:(</b></h2>
            <div className="gears">
                <div className="gear one">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <div className="gear two">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <div className="gear three">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>
        </div>

    );
}