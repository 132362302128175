import { useEffect, useState } from 'react';

import './App.css';
import HeadResources from './components/menu/Resources';
import MainMenu from './components/menu/Menu';
import Registration from './components/registration/Registration';
import Loading from './components/ui/Loading';

import { ApiCall } from './api/Api';

import MainContext from './context/Main';
import TgWebAppContext from './context/TgWebApp';
import { useContext } from 'react';


import OpenTelegram from './components/menu/telegram_move/OpenTelegram';
import ErrorConnection from './components/menu/errors/ErrorConnection';

export default function App() {

  const tgData = useContext(TgWebAppContext);

  const { GetAccount, UpdateTimestamp, GetIslands, GetCommon, PushUpdateIsland } = ApiCall()

  const [pageNavigate, setPageNavigate] = useState('loading')

  const [triggerAccountCall, setTriggerAccountCall] = useState(0)
  const [triggerIslandCall, setTriggerIslandCall] = useState(0)
  const [triggerCommonCall, setTriggerCommonCall] = useState(0)
  const [triggerPushUpdateIsland, setTriggerPushUpdateIsland] = useState(0)

  const [accountStatus, setAccountStatus] = useState()

  const [account, setAccount] = useState()
  const [commonData, setCommonData] = useState()
  const [islandResource, setIslandResource] = useState(
    [
      {
        "resources": {
          "dooblons": 0,
          "settlers": 0,
          "slaves": 0,
          "food": 0,
          "timber": 0
        }
      }
    ]
  )
  const AppData = {
    account: account,
    island: islandResource[0],
    commonData: commonData,
    triggers: {
      // accountCall: () => setTriggerAccountCall(),
      // islandCall: () => setTriggerIslandCall()
      accountCall: triggerAccountCall,
      setAccountCall: setTriggerAccountCall,
      islandCall: triggerIslandCall,
      setIslandCall: setTriggerIslandCall,
      pushUpdateIslandCall: triggerPushUpdateIsland,
      setPushUpdateIslandCall: setTriggerPushUpdateIsland,
      commonCall: triggerCommonCall,
      setCommonCall: setTriggerCommonCall
    }
  }




  ////// API calls: //////////
  // Get Account Data from backend
  useEffect(() => {
    // Set load banner:
    setPageNavigate('loading')


    // Redirect to telegram_move if initData is empty
    if (tgData.initData === "") {
      setPageNavigate('open_telegram')
      return;
    } else {

      GetAccount()
        .then(response => {
          const status = response['status']
          const data = response['data']

          setAccountStatus(status)
          setAccount(data)

          // Remove load banner
          setPageNavigate('main')

          // Send tp backend call, that user online
          UpdateTimestamp()
            .then(response => {
              const status = response['status']
              const data = response['data']
              console.log('UpdateTimestamp:', status, data)

            })
            .catch(error => {
              console.error('Error updating timestamp:', error)
            })

        })
        .catch(error => {
          console.error('Error getting account:', error);
          setPageNavigate('error_connection')
          return;
        });
    }

  }, [triggerAccountCall]);


  useEffect(() => {

    GetIslands("true")
      .then(response => {
        // const status = response['status']
        const data = response['data']

        // console.log('GetIslands:', status, data)
        setIslandResource(data)


        // Push for update:
        setTriggerPushUpdateIsland(!triggerPushUpdateIsland + 1)

      })
      .catch(error => {
        console.error('Error. Can`t get Islands:', error);
        // Handle the error, e.g., show an error message
      });

  }, [triggerIslandCall]);


  useEffect(() => {

    GetCommon()
      .then(response => {
        // const status = response['status']
        const data = response['data']

        setCommonData(data)

      })
      .catch(error => {
        console.error('Error. Can`t get common data:', error);
        // Handle the error, e.g., show an error message
      });

  }, [triggerCommonCall]);

  ////////////////////////////

  ////////////////////////////
  useEffect(() => {

    // Prevent from loading from start:
    if (typeof AppData.island !== 'undefined') {
      if (AppData.island.name) {

        // This code will run whenever the value of `triggerPushUpdate` changes
        // You can add your logic here to handle the update    
        PushUpdateIsland(AppData.island.name)
          .then(response => {
            const status = response['status']
            const data = response['data']
            console.log('PushUpdateIsland:', status, data)

          })
          .catch(error => {
            console.error('Error. Can`t PushUpdateIsland:', error);
            // Handle the error, e.g., show an error message
          });
      }
    }
  }, [triggerPushUpdateIsland]);
  ////////////////////////////




  return (
    <>


      {
        (pageNavigate === "loading") && <Loading />

      }

      {
        (pageNavigate === "open_telegram") &&
        (
          <OpenTelegram />
        )
      }
      {
        (pageNavigate === "error_connection") &&
        (
          <ErrorConnection />
        )
      }



      {
        (pageNavigate !== "loading" && accountStatus === 404) &&
        (
          <Registration />
        )
      }


      {
        (pageNavigate !== "loading" && accountStatus === 401) &&
        (
          <p>
            401 Unauthorized
          </p>
        )
      }




      {
        (pageNavigate !== "loading" && accountStatus === 200) && (


          <MainContext.Provider value={AppData}>
            <HeadResources islandResource={islandResource[0]} />
            <MainMenu />
          </MainContext.Provider>


        )
      }

      {
        (pageNavigate !== "loading" && accountStatus === 400) &&
        (
          <b>Error Connnection</b>
        )
      }

    </>
  )




}

