import TgWebAppContext from '../context/TgWebApp';
import { useContext } from 'react';

export const ApiCall = () => {
    const tgData = useContext(TgWebAppContext);

    const ghc = tgData.initData
    const lang = tgData.initDataUnsafe.user.language_code


    const IsNickNameExist = async (name) => {
        let request_body = {
            "method": "IsNickNameExist",
            "params":
            {
                "name": name
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error checking nickname:', error);
            throw error;
        }
    }

    const IsIslandExist = async (name) => {

        let request_body = {
            "method": "IsIslandExist",
            "params":
            {
                "name": name
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            }).then(response => response);

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error checking island name:', error);
            throw error;
        }
    }

    const Register = async (nickname, island_name, nation) => {

        let request_body = {
            "method": "Register",
            "params":
            {
                "nickname": nickname,
                "language": lang,
                "nation": nation,
                "island_name": island_name,
                "capital": "true"
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error during registration:', error);
            throw error;
        }
    }

    const GetAccount = async () => {

        let request_body = {
            "method": "GetAccount",
            "params":
            {
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error getting account:', error);
            throw error;
        }
    }

    const UpdateTimestamp = async () => {

        let request_body = {
            "method": "UpdateTimestamp",
            "params":
            {
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    const GetIslands = async (capital) => {

        let request_body = {
            "method": "GetIslands",
            "params":
            {
                "capital": capital,
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error getting islands:', error);
            throw error;
        }
    }

    const GetCommon = async () => {

        let request_body = {
            "method": "GetCommon",
            "params":
            {
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error getting common data:', error);
            throw error;
        }
    }

    const GetFreePeople = async (island_name) => {

        let request_body = {
            "method": "GetFreePeople",
            "params":
            {
                "island_name": island_name
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error getting common data:', error);
            throw error;
        }
    }

    const UpdateWorkers = async (building, workers, slaves, island_name) => {

        let request_body = {
            "method": "UpdateWorkers",
            "params":
            {
                "building": building,
                "workers": workers,
                "slaves": slaves,
                "island_name": island_name
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error getting common data:', error);
            throw error;
        }
    }

    const UpdateBuilding = async (building, island_name) => {

        let request_body = {
            "method": "UpdateBuilding",
            "params":
            {
                "building": building,
                "island_name": island_name
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    const PushUpdateIsland = async (island_name) => {

        let request_body = {
            "method": "PushUpdateIsland",
            "params":
            {
                "name": island_name
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error getting common data:', error);
            throw error;
        }
    }
    const GetMarketData = async () => {

        let request_body = {
            "method": "GetMarketData",
            "params":
            {
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error getting market data:', error);
            throw error;
        }
    }

    const MarketOperation = async (type, product, amount) => {

        let request_body = {
            "method": "MarketOperation",
            "params":
            {
                "type": type,
                "product": product,
                "amount": amount
            },
            "qhc": ghc
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body)
            });

            return {
                "status": response.status,
                "data": await response.json()
            }

        } catch (error) {
            console.error('Error operation on market:', error);
            throw error;
        }
    }

    return { IsNickNameExist, IsIslandExist, Register, GetAccount,  UpdateTimestamp, GetIslands, GetCommon, GetFreePeople, UpdateWorkers, UpdateBuilding, PushUpdateIsland, GetMarketData, MarketOperation};
};



