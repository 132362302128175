import TgWebAppContext from "./context/TgWebApp"
import App from "./App"

export default function RootApp() {


    // Integration with telegram
    let tg = window.Telegram.WebApp; //получаем объект webapp телеграма 
    let tgData
    tg.expand(); //расширяем на все окно  

    // Prevent windows collaps\closing\scratching
    tg.disableVerticalSwipes()


    if (typeof process.env.REACT_APP_GHC !== "undefined") {

        tgData = {
            ...tg,
            initData: process.env.REACT_APP_GHC,
            initDataUnsafe: { user: { language_code: "en" } },
        };


        // tg["initData"] = process.env.REACT_APP_GHC
        // tg["initDataUnsafe"] = { user: { language_code: "en" } }
    } else if (tg.initData === "") {
        tgData = {
            ...tg,
            initData: "",
            initDataUnsafe: { user: { language_code: "en" } },
        };
    } else {
        tgData = {
            ...tg
        };
    }

    return (
        <TgWebAppContext.Provider value={tgData}>
            <App />
        </TgWebAppContext.Provider>
    );


}