import './Exchange.css'
import { useLanguage } from '../../../../../data/language'

export function Exchange({ id, isActive, ...props }) {
    const { lang } = useLanguage()

    return (
        <div className='container_button_exchange' >
            <button id={id}
                {...props}
                className={isActive ? 'button_build_active' : 'button_build'}>

                {lang().ui_text.button_exchange}
            </button>
        </div>

    )
} 