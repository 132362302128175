import { useLanguage } from '../../data/language'
import { useState, useContext, useEffect, useRef } from 'react';
import './BuildProgress.css'
import MainContext from '../../context/Main';

function formatTime(seconds) {
    // Calculate days, hours, minutes, and seconds
    const days = Math.floor(seconds / 86400);
    seconds %= 86400;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Build the formatted time string
    let formattedTime = '';

    if (days > 0) {
        formattedTime += `${days}d `;
    }
    if (hours > 0) {
        formattedTime += `${hours}h `;
    }
    if (minutes > 0) {
        formattedTime += `${minutes}m `;
    }
    if (remainingSeconds > 0 || formattedTime === '') {
        // Include seconds if there's no other larger unit, or seconds are more than 0
        formattedTime += `${remainingSeconds}s`;
    }

    return formattedTime.trim();
}

const getBackgroundColor = (progress) => {
    if (progress <= 5) return '#f63a0f';
    if (progress <= 25) return '#f27011';
    if (progress <= 50) return '#f2b01e';
    if (progress <= 75) return '#f2d31b';
    if (progress <= 100) return '#86e01e';
    return '#f63a0f'; // Default color if out of range
};

function getPercent(start, completed) {
    const time_now = Math.round(Date.now() / 1000)

    let updated_value = Math.round((time_now - start) / (completed - start) * 100)
    if (updated_value > 100) {
        return 100
    }
    else {
        return updated_value
    }
}

function GetTimeLeft(completed) {
    const time_now = Math.round(Date.now() / 1000)

    let time_left = completed - time_now
    if (time_left <= 0) {
        return 0
    }
    return formatTime(time_left)

}



export function BuildProgress({ start, completed }) {

    const { lang } = useLanguage()

    const AppData = useContext(MainContext)

    const [percent, setPercent] = useState(getPercent(start, completed))
    const [timeLeft, setTimeLeft] = useState(GetTimeLeft(completed))


    const [buildCompleted, setBuildCompleted] = useState(false)


    /////// This part is responsible for Progress bar display /////////////
    ///////////////////////////////////////////////////////////////////////

    const intervalRef = useRef(null)

    useEffect(() => {
        if (!intervalRef.current && !buildCompleted) {
            intervalRef.current = setInterval(() => {
                const newPercent = getPercent(start, completed)
                const newTimeLeft = GetTimeLeft(completed)

                setPercent(newPercent)
                setTimeLeft(newTimeLeft)

                if (newTimeLeft <= 0 && !buildCompleted) {
                    // Trigger reload island's data from backend 
                    AppData.triggers.setIslandCall(AppData.triggers.islandCall + 1)

                    clearInterval(intervalRef.current)
                    intervalRef.current = null

                    setBuildCompleted(true)
                }
            }, 1000)
        }
        else if (buildCompleted) {

            intervalRef.current = setInterval(() => {
                // Trigger reload island's data from backend 
                AppData.triggers.setPushUpdateIslandCall(AppData.triggers.pushUpdateIslandCall + 1)
                AppData.triggers.setIslandCall(AppData.triggers.islandCall + 1)
            }, 500)
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [start, completed, buildCompleted, AppData.triggers])

    ///////////////////////////////////////////////////////////////////////




    return (
        <div className="build_in_progress">
            <p className='build_field_text'>{lang().buildings.ui_common.construction.in_progress} {percent}%</p>

            <div className="container">
                <div className="progress">
                    <div className="progress-bar"
                        style={{
                            width: `${percent}%`,
                            backgroundColor: getBackgroundColor(percent)
                        }}
                    ></div>
                </div>
            </div>
            <p className='build_field_text'>{lang().buildings.ui_common.construction.completed_in} {timeLeft}</p>


        </div>
    );
}
