import './Resources.css';
import { useLanguage } from '../../data/language';



export default function HeadResources(param) {

  const { lang } = useLanguage()

  function shortFiguresForm(number) {
    if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + "b";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "m";
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "k";
    } else {
      return number.toString();
    }
  }


  return (

    <section className='head_resources_section text' width="10%">
      <div className='head_resources'>
        <div className='dooblons'>
          <img className='res_image' alt={lang().ui_text.resources.dooblons} src='/ui/resources/dooblons.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.dooblons))}</div>
        <div className='citizen'>
          <img className='res_image' alt={lang().ui_text.resources.settlers} src='/ui/resources/settlers.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.settlers))}</div>
        <div className='slave'>
          <img className='res_image' alt={lang().ui_text.resources.slaves} src='/ui/resources/slaves.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.slaves))}</div>
        <div className='food'>
          <img className='res_image' alt={lang().ui_text.resources.food} src='/ui/resources/food.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.food))}</div>
        <div className='wood'>
          <img className='res_image' alt={lang().ui_text.resources.timber} src='/ui/resources/timber.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.timber))}</div>

        <div className='island_name'>
          <b>[{param.islandResource.name}]</b>
        </div>

        <div className='metal'>
          <img className='res_image' alt={lang().ui_text.resources.metal} src='/ui/resources/metal.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.metal))}</div>
        <div className='gunpowder'>
          <img className='res_image' alt={lang().ui_text.resources.gunpowder} src='/ui/resources/gunpowder.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.gunpowder))}</div>
        <div className='sailcloth'>
          <img className='res_image' alt={lang().ui_text.resources.sailcloth} src='/ui/resources/sailcloth.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.sailcloth))}</div>

      </div>




    </section >




  );
}
