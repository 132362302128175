import { useLanguage } from '../../data/language'
import './Buttons.css'


export function ButtonContinue({ id, isActive, ...props }) {
    const { lang } = useLanguage()

    return (
        <section>
            <button id={id}
                {...props}
                className={isActive ? 'button_continue_active' : 'button_continue'}
            >{lang().ui_text.button_continue}</button>
        </section>
    );
}

export function ButtonClose({ id, isActive, ...props }) {

    return (
        <section>
            <button id={id}
                {...props}
                className={isActive ? 'button_close_active' : 'button_close'}
            >
                <img className='img_close' src="/ui/buttons/close_1_32.png" alt="buttonClose" border="0" />
            </button>
        </section>
    );
}

export function ButtonBuild({ id, isActive, ...props }) {
    const { lang } = useLanguage()

    return (
        <button id={id}
            {...props}
            className={isActive ? 'button_build_active' : 'button_build'}
        >{lang().ui_text.button_build}</button>
    );
}

export function ButtonSave({ id, isActive, ...props }) {
    const { lang } = useLanguage()

    return (
        <button id={id}
            {...props}
            className={isActive ? 'button_save_active' : 'button_save'}
        >{lang().ui_text.button_save}</button>
    );
}

export function ButtonAssign({ id, isActive, ...props }) {

    return (
        <img className='button_assign'
            alt=""
            src='/ui/buttons/change.webp'
            {...props}
        ></img>

    );
}


