import { useLanguage } from '../../data/language';

import './BuildField.css'
import { useState, useContext } from "react"
import BuildFieldInfo from "./BuildFieldInfo"
import MainContext from '../../context/Main';



export default function BuildField(param) {
    const { lang } = useLanguage()

    const AppData = useContext(MainContext);

    let buildings_list = ['homes', 'plantations', 'timber', 'market']
    const uniq_list = ["ore", "sulfur", "flax"]
    const uniqBuildType = AppData.island.uniq_resource

    if (uniq_list.includes(uniqBuildType)) {
        buildings_list = buildings_list.concat("uniq_build")
    }

    const active_box = {
        field: "build_field",
        text: "build_field_text",
        image: "build_field_image"
    }

    const disable_box = {
        field: "build_field_hover",
        text: "build_field_text_hover",
        image: "build_field_image_hover"
    }


    const [boxClassName, setBoxClassName] = useState(
        {
            homes: active_box,
            plantations: active_box,
            timber: active_box,
            market: active_box,

            uniq_build: active_box,
            ore: active_box,
            sulfur: active_box,
            flax: active_box
        }
    )

    function handleMouseEnter(prop) {
        const timeoutIds = {};

        setBoxClassName({
            ...boxClassName,
            [prop]: disable_box
        });


        // Set a timeout to call handleMouseLeave after 1 second
        const timer = setTimeout(() => {
            handleMouseLeave(prop);
        }, 200); // 100 milliseconds = 1 second

        // Clear the previous timeout if it exists
        if (prop in timeoutIds) {
            clearTimeout(timeoutIds[prop]);
        }

        // Store the new timeout ID
        timeoutIds[prop] = timer;
    }



    function handleMouseLeave(prop) {
        setBoxClassName({
            ...boxClassName,
            [prop]: active_box
        })
    }


    return (
        <section className="text">
            {param.buildsFieldPage === 'None' &&

                buildings_list.map(build_name => {

                    const displayBuildName = build_name === 'uniq_build' ? uniqBuildType : build_name;


                    return (
                        <div key={displayBuildName}
                            className={boxClassName[displayBuildName].field}
                            onMouseEnter={() => { handleMouseEnter(displayBuildName) }}
                            onTouchStart={() => { handleMouseEnter(displayBuildName) }}
                            onMouseLeave={() => { handleMouseLeave(displayBuildName) }}
                            onTouchEnd={() => { handleMouseLeave(displayBuildName) }}

                            onClick={() => param.setBuildsFieldPage(displayBuildName)}
                        >
                            <img
                                className={boxClassName[displayBuildName].image}
                                alt=""
                                src={`/img/buildings/${AppData.account.nation}/${displayBuildName}.webp`}
                            />
                            <h4 className={boxClassName[displayBuildName].text}>
                                {lang().buildings[displayBuildName].name}
                            </h4>
                            <p className={boxClassName[displayBuildName].text}>
                                {lang().buildings[displayBuildName].description}
                            </p>
                        </div>
                    )
                })
            }


            {param.buildsFieldPage !== 'None' &&
                <BuildFieldInfo buildsFieldPage={param.buildsFieldPage} />
            }


        </section>


    )
}