import './MenuPanel.css'
import { useContext } from 'react';
import MainContext from '../../../context/Main';


export default function MenuPanel(param) {
    const AppData = useContext(MainContext);



    function handleMainButtons(props) {
        // Trigger reload island's data from backend 
        AppData.triggers.setIslandCall(AppData.triggers.islandCall + 1)

        param.setCurrentPage(props)
        param.setBuildsFieldPage('None')
    }


    return (
        <div className='fixed-footer'>


            <div id="button_main" className='button_main'>
                <img className='button_main_img'
                    alt=""
                    src='/ui/buttons/overview.webp'
                    onClick={() => handleMainButtons('main')}
                ></img>
            </div>


            <div id="button_buildings" className='button_main'>
                <img className='button_main_img'
                    alt=""
                    src='/ui/buttons/buildings.webp'
                    onClick={() => handleMainButtons('build')}
                ></img>
            </div>

            <div id="button_fleet" className='button_main'>
                <img className='button_main_img'
                    alt=""
                    src='/ui/buttons/fleet.webp'
                    onClick={() => handleMainButtons('fleet')}
                ></img>
            </div>

            <div id="button_defence" className='button_main'>
                <img className='button_main_img'
                    alt=""
                    src='/ui/buttons/defence.webp'
                    onClick={() => handleMainButtons('defence')}
                ></img>
            </div>

            <div id="button_map" className='button_main'>
                <img className='button_main_img'
                    alt=""
                    src='/ui/buttons/map.webp'
                    onClick={() => handleMainButtons('map')}
                ></img>
            </div>
        </div>

    )
}